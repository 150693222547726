// @flow
import React, {useContext} from 'react';
import {Layout, Tooltip} from 'antd';
import {NavLink} from 'react-router-dom';
import AdminMenu from '../Menu/AdminMenu';
import ProfileMenu from '../Menu/ProfileMenu';
import Profile from '../Profile';
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {selectFeedbackUnreadCount, selectMapsRequestsCount} from '../../selectors';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import logoImage from '../../assets/images/de_icon.png';
import {signOut} from '../../actions/auth';
import AppContext from '../../contexts/app';
import LanguageSwitch from '../LanguageSwitch';

const Header = () => {
  const dispatch = useDispatch();
  const unreadFeedbackCount = useSelector(selectFeedbackUnreadCount);
  const mapsRequestsCount = useSelector(selectMapsRequestsCount);
  const {mainHeading, showProfile, toggleProfile} = useContext(AppContext);
  const {isAuthenticated, profile, role} = useIsAuthenticated();
  const _signOut = () => {
    dispatch(signOut(profile?.id))
  };
  const {t} = useTranslation();

  const _toggleProfile = () => {
    toggleProfile(!showProfile);
  }

  return (
    <Layout.Header className="header header--admin">
      <div className="left">
        <Tooltip title={t('My Sprints Dashboard')}>
          <NavLink to="/">
            <div className="logo">
              <img className="logo__image" src={logoImage} alt="developmental sprint app" />
            </div>
          </NavLink>
        </Tooltip>
        <span className="text text--size-l text--weight-bold">
          <span className="superscript">{t('Developmental Sprint')}</span>
        </span>
        <span className="text text--size-l sprint-name">{t(mainHeading)}</span>
      </div>
      {isAuthenticated && (
        <>
          <div className="right menu--profile">
            {role === 'coach' && (
              <AdminMenu feedbackCount={unreadFeedbackCount} mapsRequestsCount={mapsRequestsCount} isSuper={profile.superAdmin}/>
            )}
            <ProfileMenu onSigOut={_signOut} user={profile} onProfileClick={_toggleProfile}/>
            <LanguageSwitch/>
          </div>
          <Profile visible={showProfile} onClose={_toggleProfile}/>
        </>
      )}
      {!isAuthenticated && (
        <div className="right menu--profile">
          <LanguageSwitch/>
        </div>
      )}
    </Layout.Header>
  );
};

export default Header;
