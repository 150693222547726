import {
  SIGN_IN,
  CREATE_NEW_SPRINT,
  GET_SPRINTS,
  GET_COACH_SPRINT,
  GET_COACH_DATA,
  UPDATE_COACH_SPRINT,
  SWITCH_CUSTOMERS,
  DELETE_COACH_SPRINT,
  UPDATE_SPRINTS,
  SET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE,
  SAVE_EMAIL_TEMPLATE,
  NEW_MAPS_REQUEST,
  NEW_MAPS_SUCCESS,
  MAP_UPDATE_REQUEST_REQUEST,
  MAP_UPDATE_REQUEST_SUCCESS,
  GET_ADMINS,
  DELETE_ADMIN,
  CREATE_ADMIN
} from './types';

export function signInAdmin(params) {
  return {
    type: SIGN_IN,
    params,
  };
}

export function createNewSprint(params) {
  return {
    type: CREATE_NEW_SPRINT,
    params,
  };
}

export function getSprints() {
  return {
    type: GET_SPRINTS,
  };
}

export function getCoachSprint(id) {
  return {
    type: GET_COACH_SPRINT,
    id,
  };
}

export function getAssumptionData(id) {
  return {
    type: GET_COACH_DATA,
    id,
  };
}

export function updateCoachSprint(params) {
  return {
    type: UPDATE_COACH_SPRINT,
    params,
  };
}

export function switchCustomers(params) {
  return {
    type: SWITCH_CUSTOMERS,
    params,
  };
}

export function updateSprints(params) {
  return {
    type: UPDATE_SPRINTS,
    params,
  };
}

export function deleteSprint(id) {
  return {
    type: DELETE_COACH_SPRINT,
    id,
  };
}

export function getEmailTemplate() {
  return {
    type: GET_EMAIL_TEMPLATE,
  };
}

export function setEmailTemplate(emailTemplate) {
  return {
    type: SET_EMAIL_TEMPLATE,
    emailTemplate,
  };
}

export function saveEmailTemplate(template) {
  return {
    type: SAVE_EMAIL_TEMPLATE,
    template,
  };
}


export function getMapRequests() {
  return {
    type: NEW_MAPS_REQUEST
  }
}

export function onMapRequestSuccess(requests) {
  return {
    type: NEW_MAPS_SUCCESS,
    requests
  }
}

export function updateMapsRequest({userId, sprintId, actionName}) {
  return {
    type: MAP_UPDATE_REQUEST_REQUEST,
    userId,
    sprintId,
    actionName
  };
}

export function onUpdateRequestSuccess(userId, sprintId) {
  return {
    type: MAP_UPDATE_REQUEST_SUCCESS,
    userId,
    sprintId
  };
}

export function getAdmins() {
  return {
    type: GET_ADMINS,
  };
}

export function removeAdmin(id) {
  return {
    type: DELETE_ADMIN,
    id
  };
}

export function createAdmin(data) {
  return {
    type: CREATE_ADMIN,
    data
  };
}
