import React, {useEffect, forwardRef} from 'react';
import {Form, Select, Input, Row, Col, Divider, Alert, Popover} from 'antd';
import { useTranslation, Translation } from 'react-i18next';
import {
  age as ageOptions,
  range,
  selects
} from '../../constants/selects';
import {QuestionCircleOutlined} from '@ant-design/icons';
import SupportText from '../SupportText';

const getOptions = dataSet => dataSet.map(opt => <Select.Option key={opt} value={opt}>{opt}</Select.Option>)

const ProfileForm = ({profile, isSaving, onSave, showUserFields, showPassword, isProfile = false, numberOfMaps}, ref) => {
  const formLayout = {
    labelCol: { span: showPassword ? 24 : 8},
    wrapperCol: { span: showPassword ? 24 : 16 },
  };
  const {t} = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, []);

  function onFinish(values) {
    return onSave({...profile, ...values});
  }

  const readonly = Boolean(!isProfile && profile.active);
  const emailReadonly = isProfile || profile.active;

  const emailLabel = profile.role !== 'coach'
    ? (<span>{t('Email')} &nbsp;
        <Popover
          content={
            <SupportText
              text={`${t('If you want to change your email, please')} %link%`}
              emailSubject={t('Change email request')}
            />
          }
        >
            <QuestionCircleOutlined/>
        </Popover>
        </span>
      )
    : t('Email');

  return (
    <Form
      layout={showPassword ? 'vertical' : 'horizontal'}
      {...formLayout}
      onFinish={onFinish}
      form={form}
      initialValues={profile}
      shouldUpdate
      ref={ref}
    >

      {!isProfile && profile.active && (
        <Alert message="This is an active users. You can't edit his data" className="mv-5"/>
      )}
      <Row gutter={20}>
        <Col md={12} xs={24}>
          <Form.Item label={t('First Name')} name="firstName" hasFeedback rules={[{ required: true }]}>
            <Input disabled={readonly}/>
          </Form.Item>
          <Form.Item name="lastName" label={t('Last Name')}  hasFeedback rules={[{ required: true }]}>
            <Input disabled={readonly}/>
          </Form.Item>
          <Form.Item name="email" label={emailLabel} hasFeedback rules={[{ required: true, type: 'email'}]}>
            <Input disabled={emailReadonly}/>
          </Form.Item>
          {showUserFields && (
            <>
              <Form.Item name="location" label={t('Location')}>
                <Input disabled={readonly}/>
              </Form.Item>
              <Form.Item name="department" label={t('Department')}>
                <Input disabled={readonly}/>
              </Form.Item>
              <Form.Item  name="title" label={t('Title')}>
                <Input disabled={readonly}/>
              </Form.Item>
              <Form.Item name="age" label={t('Age')} >
                <Select disabled={readonly}>
                  {getOptions(ageOptions)}
                </Select>
              </Form.Item>
            </>
          )}
        </Col>
        {showUserFields && (
          <Col md={12} xs={24}>
            {selects.map(select =>
              <Form.Item key={select.id} name={select.id} label={t(select.label)} >
                <Select disabled={readonly}>
                  {getOptions(select.options)}
                </Select>
              </Form.Item>
            )}
            {!isProfile && <Form.Item name="mapsCount" label={`#${t('Num of maps')}`} >
              <Select disabled={profile._id}>
                {getOptions(range(1, numberOfMaps || 6))}
              </Select>
            </Form.Item>}
          </Col>
        )}
      </Row>
      {showPassword && (
        <>
          <Divider/>
          <Row>
            <Col md={12} xs={24}>
              <Form.Item
                name="password"
                label={t('New Password')}
                hasFeedback
                rules={[
                  {
                    min: 6,
                    message: (<Translation>{(t) => <p>{t('Password must be minimum 6 chars length')}</p>}</Translation>),
                  },
                ]}
              >
                <Input.Password autoCapitalize="off" autoComplete="off" autoCorrect="off"/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={24}>
              <Form.Item
                hasFeedback
                name="confirm"
                label={t('Confirm New Password')}
                rules={[
                  ({getFieldValue}) => ({
                    validator(rule, value) {
                      const password = getFieldValue('password');
                      if (password && !value) {
                        return Promise.reject(
                          t('Please confirm your password'),
                        );
                      }

                      if (password && password !== value) {
                        return Promise.reject(
                          t('The two passwords that you entered do not match!'),
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password autoComplete="off"/>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
}

export default forwardRef(ProfileForm);
