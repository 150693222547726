import React, {Component} from 'react';
import {Layout, Result} from 'antd';
import Header from '../Header';
import Footer from '../Footer';

const {Content} = Layout;
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }
  
  render() {
    if (this.state.hasError && this.props.fallback) {
      const subTitle = (
        <div>
          <h1>Aaaah! Something went wrong.</h1>
          <p>Brace yourself till we get the error fixed.</p>
          <p>You may also refresh the page or try again later.</p>
        </div>
      )

      return (
        <Layout>
        <Header unreadFeedbackCount={0}/>
        <Content className="main-content main-content--auth">
          <div className="app-panel app-panel--pads">
            <Result
              status="500"
              title="500"
              subTitle={subTitle}
            />
          </div>
        </Content>
        <Footer />
      </Layout>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
