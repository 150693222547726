import axios from 'axios';
import {store} from '../store';
import {updateToken} from '../actions/auth';
import {wrapApiErrors} from '../utils';
const {REACT_APP_API_URL: apiUrl} = process.env;

const Api = {
  get(route, data, params, mock = false) {
    return this.xhr(route, data, params, 'get', mock);
  },

  put(route, data, params, mock = false) {
    return this.xhr(route, data, params, 'put', mock);
  },

  post(route, data, params, mock = false) {
    return this.xhr(route, data, params, 'post', mock);
  },

  delete(route, data, params, mock = false) {
    return this.xhr(route, data, params, 'delete', mock);
  },

  replaceVariables(route, params) {
    Object.keys(params).forEach((key) => {
      route = route.replace(`:${key}`, params[key]);
    });
    return route;
  },

  all(promises) {
    return axios.all(promises);
  },

  xhr(route, data = {}, params = {}, method) {
    const sendRequest = (axiosInstance, mockInstance = false) => {
      const url = this.replaceVariables(route, params);
      const state = store.getState();
      const {
        auth: {token},
      } = state;
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        accept: 'application/json',
      };
      const options = {
        baseURL: apiUrl,
        url,
        method,
        headers,
        crossdomain: true,
      };

      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      if (method === 'get') {
        options.params = data;
      } else {
        options.data = data;
      }
      return axiosInstance(options)
        .then((res) => {
          if (mockInstance) {
            mockInstance.reset();
            mockInstance = false;
          }
          if (res.headers.token) {
            store.dispatch(updateToken(res.headers.token));
          }
          return res.data;
        })
        .catch((error) => wrapApiErrors(error));
    };

    return sendRequest(axios.create());
  },
};

export default Api;
