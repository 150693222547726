// @flow
import React, {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {withRouter} from 'react-router';
import {useMediaQuery} from 'react-responsive';
import {Menu, Badge, Dropdown} from 'antd';
import  { MenuOutlined } from '@ant-design/icons';

import {
  AreaChartOutlined,
  WarningOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  TrophyOutlined,
  TeamOutlined
} from '@ant-design/icons';
import {getFeedback} from '../../../actions/feedback';
import {getMapRequests} from '../../../actions/admin';
import {getEmailTemplate} from '../../../actions/admin';
import {useDispatch} from 'react-redux';
const {Item} = Menu;

type Props = {
  feedbackCount: number,
  location: {
    pathname: string,
  },
};

const menu = [
  {
    key: 'sprints',
    IconComponent: AreaChartOutlined,
    title: 'Sprints',
  },
  {
    key: 'workouts',
    IconComponent: TrophyOutlined,
    title: 'Workouts',
  },
  {
    key: 'requests',
    IconComponent: QuestionCircleOutlined,
    title: 'Map Requests',
  },
  {
    key: 'email-template',
    IconComponent: MailOutlined,
    title: 'Email Templates',
  },
  {
    key: 'feedbacks',
    IconComponent: WarningOutlined,
    title: 'Feedback',
  },
];

function renderMenuItem({key, IconComponent, title}, feedbackCount, mapsRequestsCount) {
  return (
    <Item key={key} icon={<IconComponent/>}>
      <RouterLink to={`/admin/${key}`}>{title}</RouterLink>
      {key === 'feedbacks' && <Badge count={feedbackCount} />}
      {key === 'requests' && <Badge count={mapsRequestsCount} />}
    </Item>
  );
}

function AdminMenu(props: Props) {
  const dispatch = useDispatch();
  const _getFeedBack = () => dispatch(getFeedback());
  const _getMapRequests = () => dispatch(getMapRequests());
  const _getEmailTemplate = () => dispatch(getEmailTemplate());

  const {
    location: {pathname},
    feedbackCount,
    mapsRequestsCount,
    isSuper
  } = props;

  const selectedKeys = menu.reduce((keys, curr) => {
    if (pathname.includes(curr.key)) {
      return [...keys, curr.key];
    }

    return keys;
  }, []);

  useEffect(() => {
    _getFeedBack();
    _getEmailTemplate();
    _getMapRequests();
    // eslint-disable-next-line
  }, [])

  const isDesktopOrLaptop = useMediaQuery(
    { maxDeviceWidth: 1124 }
 );

  const menuSimple = (
    <Menu mode={isDesktopOrLaptop ? 'vertical' : 'horizontal'} selectedKeys={selectedKeys} className="admin-menu">
      {menu.map((item) => renderMenuItem(item, feedbackCount, mapsRequestsCount))}
      {isSuper &&
        <Item key="admins" icon={<TeamOutlined/>}>
          <RouterLink to={`/admin/admins`}>Admins</RouterLink>
        </Item>
      }
    </Menu>
  );

  return (
    isDesktopOrLaptop
    ? (
        <Dropdown overlay={menuSimple} placement="bottomCenter" >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            <MenuOutlined />
          </a>
        </Dropdown>
      )
    : menuSimple

  )
}

export default withRouter(AdminMenu);
