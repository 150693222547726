import React from 'react';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next'
const {REACT_APP_SUPPORT_EMAIL} = process.env;


export default function SupportText({text, emailSubject = 'Request for TDE support', small}) {
  const href = `mailto:${REACT_APP_SUPPORT_EMAIL}?subject=${emailSubject}`;
  let parts = text.split('%link%');
  const {t} = useTranslation()

  return <p className={classnames('support-text', {'text--size-sm': small, 'top-space--sm': small})}>{parts[0]}<a href={href}>{t('contact support')}</a>{parts[1]}</p>;
}
