import {GET_FEEDBACK, REVIEW_FEEDBACK} from './types';

export function getFeedback() {
  return {
    type: GET_FEEDBACK,
  };
}

export function reviewFeedback(params) {
  return {
    type: REVIEW_FEEDBACK,
    params,
  };
}
