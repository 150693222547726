// @flow
import React, {useCallback} from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next'
import {Layout, Result, Button} from 'antd';
import Header from '../../Header';
import Footer from '../../Footer';
const {Content} = Layout;

const NotFound = () => {
  const history = useHistory();
  const back = useCallback(() => {
    history.replace('/')
  }, [history]);

  const {t} = useTranslation()

  return (
    <Layout>
      <Header unreadFeedbackCount={0}/>
      <Content className="main-content main-content--auth">
        <div className="app-panel app-panel--pads">
          <Result
            status="404"
            title="404"
            subTitle={t('Sorry, the page you visited does not exist')}
            extra={<Button type="primary" onClick={back}>{t('Back Home')}</Button>}
          />,
        </div>
      </Content>
      <Footer />
    </Layout>
  );
};

export default NotFound;
