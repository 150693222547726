import {all} from 'redux-saga/effects';
import {
  getAdminSignInWatcher,
  createNewSprintWatcher,
  getSprintsWatcher,
  getWorkoutsWatcher,
  getCoachSprintWatcher,
  updateCoachSprintWatcher,
  deleteCoachSprintWatcher,
  getFeedbackWatcher,
  getEmailTemplateWatcher,
  saveEmailTemplateWatcher,
  reviewFeedbackWatcher,
  getRequestsWatcher,
  requestUpdateWatcher,
  getSprintDataWatcher,
  getAdminsWatcher,
  removeAdminWatcher,
  createAdminWatcher,
} from './admin';

import {
  getSprintWatcher,
  getUserSprintsWatcher,
  requestMapsWatcher,
  saveTextEntryWatcher,
  deleteTextEntryWatcher,
  saveBigAssumptionWatcher,
  deleteBigAssumptionWatcher,
  saveGoalWatcher,
  saveExperimentEntryWatcher,
  updateExperimentEntryWatcher,
  updateBigAssumptionsOrderWatcher,
  updateTextEntriesOrderWatcher,
  updateExperimentsOrderWatcher,
  updateTakingStockQuestionWatcher,
  deleteTakingStockQuestionEntryWatcher,
  updateTakingStockRangeQuestionWatcher,
  toggleBigAssumptionWatcher,
  createMapsWatcher
} from './sprint';

import {
  getLogInWatcher,
  getSingUpWatcher,
  getInvitationDataWatcher,
  forgotPasswordRequestWatcher,
  checkResetCodeWatcher,
  resetPasswordWatcher,
  updateProfileWatcher,
  agreementWatcher,
  getLogInSSOWatcher,
  confirmationWatcher,
  resendConfirmationCodeWatcher,
  signOutWatcher
} from './auth';

import {
  getUserWorkoutsWatcher,
  getUserWorkoutWatcher,
  deleteUserWorkoutWatcher,
  deleteUserWorkoutsWatcher,
  createUserWorkoutsWatcher,
  saveWorkoutAssumptionWatcher,
  deleteWorkoutAssumptionWatcher,
  saveWorkoutExperimentWatcher,
  deleteWorkoutExperimentWatcher,
  toggleWorkoutAssumptionWatcher,
  updateWorkoutExperimentWatcher,
  saveWorkoutNoteWatcher,
  deleteWorkoutNoteWatcher,
  saveWorkoutNoteChallengeWatcher,
  deleteWorkoutNoteChallengeWatcher,
  savePodAssumptionWatcher,
  deletePodAssumptionWatcher,
  savePodExperimentWatcher,
  updatePodExperimentWatcher,
  deletePodExperimentWatcher,
  togglePodAssumptionWatcher,
  savePodNoteDevelopmentWatcher,
  deletePodNoteDevelopmentWatcher,
  saveWorkoutQuestionWatcher,
  updateUserWorkoutsWatcher
} from './workout';

import {
  getUserPodsWatcher,
  getUserPodWatcher,
  savePodNoteWatcher,
  deletePodNoteWatcher,
} from './pod';

export default function* IndexSaga() {
  yield all([
    getLogInWatcher(),
    getSingUpWatcher(),
    getLogInSSOWatcher(),
    getAdminSignInWatcher(),
    createNewSprintWatcher(),
    getSprintsWatcher(),
    getWorkoutsWatcher(),
    getSprintWatcher(),
    getUserSprintsWatcher(),
    requestMapsWatcher(),
    getCoachSprintWatcher(),
    getSprintDataWatcher(),
    updateCoachSprintWatcher(),
    deleteCoachSprintWatcher(),
    getFeedbackWatcher(),
    getEmailTemplateWatcher(),
    saveEmailTemplateWatcher(),
    reviewFeedbackWatcher(),
    saveTextEntryWatcher(),
    deleteTextEntryWatcher(),
    saveBigAssumptionWatcher(),
    deleteBigAssumptionWatcher(),
    saveGoalWatcher(),
    saveExperimentEntryWatcher(),
    updateExperimentEntryWatcher(),
    updateBigAssumptionsOrderWatcher(),
    updateTextEntriesOrderWatcher(),
    updateExperimentsOrderWatcher(),
    updateTakingStockQuestionWatcher(),
    deleteTakingStockQuestionEntryWatcher(),
    updateTakingStockRangeQuestionWatcher(),
    toggleBigAssumptionWatcher(),
    getInvitationDataWatcher(),
    forgotPasswordRequestWatcher(),
    checkResetCodeWatcher(),
    resetPasswordWatcher(),
    updateProfileWatcher(),
    getRequestsWatcher(),
    requestUpdateWatcher(),
    createMapsWatcher(),
    agreementWatcher(),
    getUserWorkoutsWatcher(),
    getUserWorkoutWatcher(),
    saveWorkoutAssumptionWatcher(),
    deleteWorkoutAssumptionWatcher(),
    saveWorkoutExperimentWatcher(),
    deleteWorkoutExperimentWatcher(),
    toggleWorkoutAssumptionWatcher(),
    updateWorkoutExperimentWatcher(),
    saveWorkoutNoteWatcher(),
    deleteWorkoutNoteWatcher(),
    saveWorkoutNoteChallengeWatcher(),
    deleteWorkoutNoteChallengeWatcher(),
    getUserPodsWatcher(),
    getUserPodWatcher(),
    savePodNoteDevelopmentWatcher(),
    deletePodNoteDevelopmentWatcher(),
    savePodNoteWatcher(),
    deletePodNoteWatcher(),
    savePodAssumptionWatcher(),
    deletePodAssumptionWatcher(),
    savePodExperimentWatcher(),
    updatePodExperimentWatcher(),
    deletePodExperimentWatcher(),
    togglePodAssumptionWatcher(),
    deleteUserWorkoutWatcher(),
    deleteUserWorkoutsWatcher(),
    createUserWorkoutsWatcher(),
    saveWorkoutQuestionWatcher(),
    getAdminsWatcher(),
    removeAdminWatcher(),
    createAdminWatcher(),
    updateUserWorkoutsWatcher(),
    confirmationWatcher(),
    resendConfirmationCodeWatcher(),
    signOutWatcher(),
  ]);
}
