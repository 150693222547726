import {createReducer} from '../utils';
import {
  SET_USER_SPRINT,
  UPDATE_TEXT_ENTRY,
  SET_EXPERIMENTS,
  UPDATE_BIG_ASSUMPTIONS,
  UPDATE_BIG_ASSUMPTION,
  SET_QUESTION_FOR_TAKING_STOCK,
  GET_USER_SPRINT,
  ON_API_ERROR
} from '../actions/types';

const initialState = null;

const sprintReducer = createReducer(initialState, {
  [GET_USER_SPRINT]: () => ({loading: true}),
  [SET_USER_SPRINT]: (state, {sprint}) => {
    return {
      ...sprint,
    };
  },
  [UPDATE_TEXT_ENTRY]: (state, newEntries) => {
    return {
      ...state,
      goal: {
        ...state.goal,
        ...newEntries.value,
      },
    };
  },
  [UPDATE_BIG_ASSUMPTIONS]: (state, {bigAssumptions}) => {
    return {
      ...state,
      goal: {
        ...state.goal,
        bigAssumptions,
      },
    };
  },
  [UPDATE_BIG_ASSUMPTION]: (state, {assumptionId, field, value}) => {
    const {
      goal: {bigAssumptions},
    } = state;
    const assumptionToUpdate = bigAssumptions.find(
      (assumption) => assumption._id === assumptionId,
    );

    if (assumptionToUpdate) {
      assumptionToUpdate[field] = value;
    }

    return {
      ...state,
      goal: {
        ...state.goal,
        bigAssumptions: [...bigAssumptions],
      },
    };
  },
  [SET_EXPERIMENTS]: (state, {bigAssumption}) => {
    let oldBigAssumptionIndex;
    state.goal.bigAssumptions.forEach((bigAss, index) => {
      if (bigAss._id === bigAssumption._id) {
        oldBigAssumptionIndex = index;
      }
    });
    const newAssumptions = [...state.goal.bigAssumptions];
    newAssumptions[oldBigAssumptionIndex] = {...bigAssumption};

    return {
      ...state,
      goal: {
        ...state.goal,
        bigAssumptions: [...newAssumptions],
      },
    };
  },
  [SET_QUESTION_FOR_TAKING_STOCK]: (state, newQuestionValue) => {
    return {
      ...state,
      goal: {
        ...state.goal,
        takingStock: {
          ...state.goal.takingStock,
          ...newQuestionValue.value,
        },
      },
    };
  },
  [ON_API_ERROR]: () => initialState
});

export default sprintReducer;
