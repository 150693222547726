import React, {useState} from 'react';
import AppContext from '../app';

export default function AppContextProvider({children}) {
  const [mainHeading, setMainHeading] = useState('');
  const [showProfile, toggleProfile] = useState(false);

  return (
    <AppContext.Provider value={{mainHeading, setMainHeading, showProfile, toggleProfile}}>
      {children}
    </AppContext.Provider>
  );
}
