import {createReducer} from '../utils';
import {
  SET_USER_WORKOUTS,
  SET_USER_WORKOUT,
  GET_USER_WORKOUTS,
  SET_WORKOUT_BIG_ASSUMPTION,
  REMOVE_WORKOUT_BIG_ASSUMPTION,
  SET_WORKOUT_EXPERIMENT,
  UPDATE_WORKOUT_BIG_ASSUMPTION,
  ON_API_ERROR,
  SET_WORKOUT_PURPOSE_NOTES,
  SET_WORKOUT_CHALLENGE_QUESTION,
  REMOVE_WORKOUT_NOTE,
  SET_WORKOUT_CHALLENGE_NOTES,
  REMOVE_WORKOUT_CHALLENGE_NOTE,
  SET_WORKOUT_SESSION,
  SET_POD_DEVELOPMENT_NOTES,
  SET_POD_BIG_ASSUMPTION,
  SET_POD_EXPERIMENT,
  REMOVE_POD_BIG_ASSUMPTION,
  REMOVE_POD_DEVELOPMENT_NOTE,
  UPDATE_POD_BIG_ASSUMPTION,
  SET_CURRENT_SECTION
} from '../actions/types';

const initialState = {
  workouts: [],
};


const isNovartis = Boolean(process.env.REACT_APP_NOVARTIS);

const workoutReducer = createReducer(initialState, {
  [GET_USER_WORKOUTS]: (state) => ({...state, loading: false}),
  [SET_WORKOUT_PURPOSE_NOTES]: (state, {notes, field}) => {
    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        purpose: {
          ...state.currentWorkout.purpose,
          [field]: notes
        },
      }
    };
  },
  [SET_WORKOUT_CHALLENGE_NOTES]: (state, {notes, field, entityId}) => {
    const newChallenges = state.currentWorkout.challenges.map(item => {
      if (item._id === entityId) {
        return {
          ...item,
          [field]: notes,
        };
      }
      
      return item;
    });

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        challenges: newChallenges,
      }
    };
  },
  [SET_WORKOUT_CHALLENGE_QUESTION]: (state, {fieldValue, field, fieldId}) => {
    const newChallenges = state.currentWorkout.challenges.map(item => {
      if (item._id === fieldId) {
        return {
          ...item,
          [field]: fieldValue,
        };
      }
      
      return item;
    });

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        challenges: newChallenges,
      }
    };
  },
  [SET_USER_WORKOUTS]: (state, {workouts}) => {
    return {
      ...state,
      workouts,
    };
  },
  [SET_USER_WORKOUT]: (state, {workout}) => {
    return {
      ...state,
      currentWorkout: {
        ...workout,
        currentSession: isNovartis ? 1 : 1,
        currentSection: {section: 'prework', needScroll: false}
      },
    };
  },
  [SET_WORKOUT_SESSION]: (state, {params: {currentSession}}) => {
    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        currentSession,
      },
    };
  },
  [SET_WORKOUT_BIG_ASSUMPTION]: (state, {assumptions, entityId}) => {
    const challenges = state.currentWorkout.challenges;
    const updatedChallenge = challenges.find(({_id}) => _id === entityId);
    updatedChallenge.bigAssumptions = assumptions;
    const updatedChallenges = challenges.map(challenge => {
      if (challenge._id === entityId) {
        return updatedChallenge;
      }

      return challenge;
    });

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        challenges: updatedChallenges
      },
    };
  },
  [SET_WORKOUT_EXPERIMENT]: (state, {bigAssumption, entityId}) => {
    const challenges = state.currentWorkout.challenges;
    const updatedChallenge = challenges.find(({_id}) => _id === entityId);

    updatedChallenge.bigAssumptions = updatedChallenge.bigAssumptions.map(assumption => {
      if (assumption._id === bigAssumption._id) {
        return bigAssumption;
      }

      return assumption;
    });

    const updatedChallenges = challenges.map(challenge => {
      if (challenge._id === entityId) {
        return updatedChallenge;
      }

      return challenge;
    });

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        challenges: updatedChallenges
      },
    };
  },
  [REMOVE_WORKOUT_BIG_ASSUMPTION]: (state, {bigAssumptionId, entityId}) => {
    const challenges = state.currentWorkout.challenges;
    const updatedChallenge = challenges.find(({_id}) => _id === entityId);
    updatedChallenge.bigAssumptions = updatedChallenge.bigAssumptions.filter(({_id}) => _id !== bigAssumptionId);
    const updatedChallenges = challenges.map(challenge => {
      if (challenge._id === entityId) {
        return updatedChallenge;
      }

      return challenge;
    });

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        challenges: updatedChallenges
      },
    };
  },
  [REMOVE_WORKOUT_NOTE]: (state, {entryId, field}) => {
    const updatedField = state.currentWorkout.purpose[field].filter(({_id}) => _id !== entryId);

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        purpose: {
          ...state.currentWorkout.purpose,
          [field]: [...updatedField]
        }
      },
    };
  },
  [REMOVE_WORKOUT_CHALLENGE_NOTE]: (state, {entryId, field}) => {
    const currentWorkout = state.currentWorkout;
    const currentChallengeIndex = currentWorkout.currentSession - 1;
    const updatedField = currentWorkout.challenges[currentChallengeIndex][field].filter(({_id}) => 
      _id !== entryId);
    const updatedChallenges = currentWorkout.challenges;
    updatedChallenges[currentChallengeIndex][field] = updatedField;

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        challenges: updatedChallenges
      },
    };
  },
  [UPDATE_WORKOUT_BIG_ASSUMPTION]: (state, {assumptionId, field, value, entityId}) => {
    const {currentWorkout: {challenges}} = state;
    const updatedChallenge = challenges.find(({_id}) => _id === entityId);
    updatedChallenge.bigAssumptions = updatedChallenge.bigAssumptions.map(assumption => {
      if (assumption._id === assumptionId) {
        return {
          ...assumption,
          [field]: value
        };
      }

      return assumption;
    });

    const updatedChallenges = challenges.map(challenge => {
      if (challenge._id === entityId) {
        return updatedChallenge;
      }

      return challenge;
    });

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        challenges: updatedChallenges
      },
    };
  },
  // DEVELOPMENT
  [SET_POD_DEVELOPMENT_NOTES]: (state, {notes, field}) => {
    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        development: {
          ...state.currentWorkout.development,
          [field]: notes
        },
      }
    };
  },
  [SET_POD_BIG_ASSUMPTION]: (state, {assumptions}) => {
    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        development: {
          ...state.currentWorkout.development,
          bigAssumptions: assumptions
        },
      }
    };
  },
  [SET_POD_EXPERIMENT]: (state, {bigAssumption}) => {
    const {bigAssumptions} = state.currentWorkout.development;

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        development: {
          ...state.currentWorkout.development,
          bigAssumptions: bigAssumptions.map(assumption => {
            if (assumption._id === bigAssumption._id) {
              return bigAssumption;
            }
      
            return assumption;
          })
        },
      }
    };
  },
  [REMOVE_POD_BIG_ASSUMPTION]: (state, {bigAssumptionId}) => {
    const {bigAssumptions} = state.currentWorkout.development;

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        development: {
          ...state.currentWorkout.development,
          bigAssumptions: bigAssumptions.filter(({_id}) => _id !== bigAssumptionId)
        },
      }
    };
  },
  [REMOVE_POD_DEVELOPMENT_NOTE]: (state, {entryId, field}) => {
    const notes = state.currentWorkout.development[field].filter(({_id}) => 
    _id !== entryId);

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        development: {
          ...state.currentWorkout.development,
          [field]: notes
        },
      }
    };
  },
  [UPDATE_POD_BIG_ASSUMPTION]: (state, {assumptionId, field, value}) => {
    const {currentWorkout: {development: {bigAssumptions}}} = state;

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        development: {
          ...state.currentWorkout.development,
          bigAssumptions: bigAssumptions.map(assumption => {
            if (assumption._id === assumptionId) {
              return {
                ...assumption,
                [field]: value
              };
            }
      
            return assumption;
          })
        },
      }
    };
  },
  [SET_CURRENT_SECTION]: (state, {currentSection}) => {
    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        currentSection,
      },
    };
  },
  [ON_API_ERROR]: () => initialState
});

export default workoutReducer;
