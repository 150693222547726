import React from 'react';
import {useTranslation} from 'react-i18next'
import {Menu, Dropdown, Avatar} from 'antd';
import {UserOutlined, EditFilled, LogoutOutlined} from '@ant-design/icons';

type Props = {
  onSigOut: () => void,
  onProfileClick: () => void
};

function ProfileMenu({onSigOut, user, onProfileClick}: Props) {
  const {fullName} = user;
  const {t} = useTranslation();

  return (
    <Dropdown
      trigger={['click', 'hover']}
      overlay={
        <Menu>
          <Menu.Item key="0" onClick={onProfileClick}>
            <EditFilled /><span>{fullName}</span>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="3" onClick={onSigOut}>
            <LogoutOutlined/>{t('Sign Out')}
          </Menu.Item>
        </Menu>
      }>
      <Avatar size="large" icon={<UserOutlined />} />
    </Dropdown>
  );
}

export default ProfileMenu;
