export const genders = ['M', 'F'];
export const tenure = [
  'Zero-One',
  'One-Three',
  'Three-Five',
  'Five-Ten',
  'Ten-Twenty',
  'Twenty Plus',
];
export const age = ['18-24', '25-29', '30-39', '40-49', '50+'];
export const generation = [
  'Born 1997-Present',
  'Born 1981-1996',
  'Born 1965-1980',
  'Born 1946-64',
];
export const manager = ['Yes', 'No'];
export const ethnicity = [
  'Hispanic or Latino',
  'American Indian or Alaskan',
  'Asian',
  'Pacific Islander',
  'African American',
  'White',
  'Multiracial or Multiethnic',
];

export const range = (start = 0, number) => {
  return [...Array(number + 1).keys()].slice(start);
}

export const groups = range(0, 10);
export const level = range(0, 5);

export const selects = [
  {
    id: 'generation',
    label: 'Generation',
    options: generation,
  },
  {
    id: 'gender',
    label: 'Gender',
    options: genders,
  },
  {
    id: 'tenure',
    label: 'Tenure',
    options: tenure,
  },
  {
    id: 'age',
    label: 'Age',
    options: age,
  },
  {
    id: 'isManager',
    label: 'Manager',
    options: manager,
  },
  {
    id: 'ethnicity',
    label: 'Ethnicity',
    options: ethnicity,
  },
  {
    id: 'group',
    label: 'Group',
    options: groups,
  },
  {
    id: 'level',
    label: 'Level',
    options: level,
  }
];