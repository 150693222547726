// @flow
import {push, replace, goBack} from 'connected-react-router';
import {signOut} from '../actions/auth';
import {store} from '../store';

class ApiError extends Error {
  constructor(message, status) {
    super();
    this.message = message;
    this.status = status;
  }
}

export function createReducer(initialState: any, handlers: any) {
  return function reducer(state: any = initialState, action: any) {
    if (handlers[action.type]) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}

export function wrapApiErrors(error) {
  const {response = {}} = error;
  const {data, status} = response;
  const state = store.getState();
  const {
    auth: {profile},
  } = state;

  if (status === 401 && profile?.id) {
    store.dispatch(signOut(profile?.id));
    
    return Promise.reject({
      status,
      message: 'Session Expired',
    });
  }

  if (status === 401) {
    return Promise.reject({
      status,
      message: 'Unauthorized Error',
    });
  }

  if (status === 400) {
    return Promise.reject({
      status,
      message: data.message || 'Unknown Api Error',
    });
  }

  if (!data) {
    throw new Error('Unknown Api Error');
  }
  const {message: respErrorText} = data;
  throw new ApiError(respErrorText, status);
}

export function forwardTo({location, needReplace, state = {}}) {
  const action = needReplace ? replace : push;
  if (location === 'back') {
    return store.dispatch(goBack());
  }
  return store.dispatch(action(location, state));
}

export function getUrlParams(search: string) {
  const pureSearch = search.slice(search.indexOf('?') + 1);
  if (!pureSearch) {
    return {};
  }
  const hashes = pureSearch.split('&');
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split('=');
    return {
      ...params,
      [key]: decodeURIComponent(val),
    };
  }, {});
}

export function htmlEscape(str: string) {
  return str
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}

export function moveCursorToEnd(event) {
  const el = event.target;

  if (typeof el.selectionStart == "number") {
    el.selectionStart = el.selectionEnd = el.value.length;
  } else if (typeof el.createTextRange != "undefined") {
    el.focus();
    const range = el.createTextRange();
    range.collapse(false);
    range.select();
  }
}
