import {put, call, takeLatest} from 'redux-saga/effects';

import {
  LOGIN_REQUEST,
  ON_MESSAGE,
  GET_INVITATION_DATA_REQUEST,
  ON_CODE_ERROR,
  SIGNUP_REQUEST,
  ON_API_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_CODE_REQUEST,
  RESET_PASSWORD_REQUEST,
  UPDATE_PROFILE_REQUEST,
  AGREEMENT_REQUEST,
  LOGIN_REQUEST_SSO,
  CONFIRMATION_REQUEST,
  RESEND_CONFIRMATION_CODE,
  ON_SIGN_OUT_REQUEST,
  ON_SIGN_OUT,
} from '../../actions/types';
import {
  onInvitationDataSuccess,
  onAuthSuccess,
  onSignUpSuccess,
  onPasswordCodeSuccess,
  onProfileUpdated,
} from '../../actions/auth';
import Api from '../../utils/api';
import {forwardTo} from "../../utils";

function loginApi(params) {
  return Api.post('/login', params);
}

function signOutApi(id) {
  return Api.post(`/sign-out/${id}`);
}

function loginSSOApi(params) {
  return Api.post('/login/sso', params);
}

function signUpApi(params) {
  return Api.post('/sign-up', params);
}

function confirmApi(params) {
  return Api.post('/confirm', params);
}

function resendConfirmationCodeApi(email) {
  return Api.post('/send-confirmation', {email});
}

function getInvitationDataApi(invitationId) {
  return Api.get(`/invitation/${invitationId}`);
}

function resetPasswordRequestApi(email) {
  return Api.post('/reset-password/request', {email});
}

function checkPasswordCodeApi(code) {
  return Api.get(`/reset-password/${code}`);
}

function resetPasswordApi({password, passwordResetCode, id}) {
  return Api.post(`/reset-password/update`, {password, passwordResetCode, id});
}

function updateProfileApi(id, data) {
  return Api.put(`/users/${id}`, data);
}

function agreementApi() {
  return Api.post('/users/agree');
}

function* onSuccess(message) {
  yield put({
    type: ON_MESSAGE,
    message: message,
    messageType: 'success',
  });
}

function* onError(error) {
  yield put({
    type: ON_MESSAGE,
    message: error,
    messageType: 'error',
  });

  yield put({
    type: ON_API_ERROR,
  });
}

function* forgotPasswordRequest({email}) {
  try {
    yield call(resetPasswordRequestApi, email);
    yield put({type: FORGOT_PASSWORD_SUCCESS});
  } catch (error) {
    yield onError(error);
  }
}

function* checkPasswordCodeSaga({code}) {
  try {
    const {user} = yield call(checkPasswordCodeApi, code);

    yield put(onPasswordCodeSuccess(user))

  } catch (error) {
    const {status} = error;

    if (status === 400) {
      yield put({type: ON_CODE_ERROR});
      return;
    }

    yield onError(error);
  }
}

function* resetPasswordSaga({password, passwordResetCode, id}) {
  try {
    yield call(resetPasswordApi, {password, passwordResetCode, id});
    yield put(onPasswordCodeSuccess())
    yield onSuccess('Your password was changed');

    window.location.href = process.env.REACT_APP_AUTH_URL;
  } catch (error) {
    yield onError(error);
  }
}

function* getInvitationData({invitationId}) {
  try {
    const {user} = yield call(getInvitationDataApi, invitationId);

    yield put(onInvitationDataSuccess(user));
  } catch (error) {
    const {status} = error;

    if (status === 400) {
      yield put({type: ON_CODE_ERROR});
      return;
    }

    yield onError(error);
  }
}

function* logIn(action) {
  try {
    const {email, password} = action;
    const authData = yield call(loginApi, {email, password});

    yield put(onAuthSuccess(authData));
  } catch (error) {
    yield onError(error);
  }
}

function* signOut(action) {
  try {
    const {id} = action;
    yield call(signOutApi, id);
    yield put({type: ON_SIGN_OUT});
    window.location.href = process.env.REACT_APP_AUTH_URL;
  } catch (error) {
    yield onError(error);
    yield put({type: ON_SIGN_OUT});
    window.location.href = process.env.REACT_APP_AUTH_URL;
  }
}

function* logInSSO(action) {
  try {
    const {token} = action;
    const authData = yield call(loginSSOApi, {token});

    yield put(onAuthSuccess(authData));
  } catch (error) {
    yield onError(error);
  }
}

function* signUp(action) {
  try {
    const {id, password, invitationId: invitationCode} = action;
    yield call(signUpApi, {id, password, invitationCode});

    yield put(onSignUpSuccess());
  } catch (error) {
    yield onError(error);
  }
}

function* confirm(action) {
  try {
    const {id, email, code} = action;
    yield call(confirmApi, {id, email, code});
    yield onSuccess('Profile was successfully confirmed.');

    window.location.href = process.env.REACT_APP_AUTH_URL;

    yield put(onSignUpSuccess());
  } catch (error) {
    yield onError(error);
  }
}

function* resendConfirmCode(action) {
  try {
    const {email} = action;
    yield call(resendConfirmationCodeApi,  email);
    yield onSuccess('Code was sent to your email.');
  } catch (error) {
    yield onError(error);
  }
}

function* updateProfileSaga({id, data}) {
  try {
    const profile = yield call(updateProfileApi, id, data);

    yield onSuccess('Profile was successfully updated.');
    yield put(onProfileUpdated(profile));
  } catch (error) {
    yield onError(error);
  }
}

function* agreement() {
  try {
    const profile = yield call(agreementApi);

    yield put(onProfileUpdated(profile));

    forwardTo({location: '/dashboard'})
  } catch (error) {
    yield onError(error);
  }
}


export function* getLogInWatcher() {
  yield takeLatest(LOGIN_REQUEST, logIn);
}

export function* getLogInSSOWatcher() {
  yield takeLatest(LOGIN_REQUEST_SSO, logInSSO);
}

export function* getSingUpWatcher() {
  yield takeLatest(SIGNUP_REQUEST, signUp);
}

export function* getInvitationDataWatcher() {
  yield takeLatest(GET_INVITATION_DATA_REQUEST, getInvitationData);
}

export function* forgotPasswordRequestWatcher() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordRequest);
}

export function* checkResetCodeWatcher() {
  yield takeLatest(RESET_PASSWORD_CODE_REQUEST, checkPasswordCodeSaga);
}

export function* resetPasswordWatcher() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga);
}

export function* updateProfileWatcher() {
  yield takeLatest(UPDATE_PROFILE_REQUEST, updateProfileSaga);
}

export function* agreementWatcher() {
  yield takeLatest(AGREEMENT_REQUEST, agreement);
}

export function* confirmationWatcher() {
  yield takeLatest(CONFIRMATION_REQUEST, confirm);
}

export function* resendConfirmationCodeWatcher() {
  yield takeLatest(RESEND_CONFIRMATION_CODE, resendConfirmCode);
}

export function* signOutWatcher() {
  yield takeLatest(ON_SIGN_OUT_REQUEST, signOut);
}
