import {createSelector} from 'reselect';

const selectAdminFeedback = ({admin}) => admin.feedback;
const selectMapRequests = ({admin}) => admin.requests;

export const selectFeedbackUnreadCount = createSelector(
  selectAdminFeedback,
  (feedback) => {
    return feedback.filter((item) => !item.reviewed).length;
  },
);

export const selectMapsRequestsCount = createSelector(
  selectMapRequests,
  (requests) => {
    return requests.reduce((count, request) => {
      return count + request.coachSprints.length;
    }, 0)
  },
);
