// @flow

import React, {useRef, memo, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Drawer, Row} from 'antd';
import ProfileForm from "../ProfileForm";
import {useTranslation} from 'react-i18next'
import {updateProfile} from "../../actions/auth";

type Props = {
  visible: boolean,
  onClose: () => void
}

const Profile = (props: Props) => {
  const {visible, onClose} = props;
  const {t} = useTranslation();
  const {profile, inProgress} = useSelector(({auth}) => auth);
  const {messageType} = useSelector(({messages}) => messages);
  const {id} = profile;
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const _updateProfile = useCallback((data) => {
    dispatch(updateProfile(id, data))
  }, [id, dispatch]);

  const onSaveClick = async () => {
    const form = formRef.current;
    form.submit();
  }

  const _onClose = useCallback(() => {
    onClose();
  }, [onClose])

  useEffect(() => {
    if (messageType === 'success' && visible) {
      _onClose();
    }
  }, [messageType, _onClose, visible])

  return (
    <Drawer
      title={t('Edit Profile')}
      visible={visible}
      className="profile__drawer"
      destroyOnClose
      onClose={_onClose}
      footer={
        <Row justify="end">
          <Col>
            <Button
              loading={inProgress}
              onClick={onSaveClick}
              type="primary"
            >
              {t('Save')}
            </Button>
          </Col>
        </Row>
      }
    >
      {<ProfileForm
        ref={formRef}
        profile={profile}
        showPassword
        showUserFields={false}
        isProfile
        onSave={_updateProfile}
      />}
    </Drawer>
  )
}


export default memo(Profile);
