// @flow
import React from 'react';
import {footerLinks} from './config'

const Footer = (footerProps = {}) => {
  const date = new Date();
  const openCookiesManager = (event) => {
    event.preventDefault();

    window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
  };

  return (
    <footer className="footer" {...footerProps}>
      <div className="footer__text text--size-sm privacy-links-wrapper">
        <a className="link link--bordered" href="/" onClick={openCookiesManager}>
          Cookie Preferences
        </a>
        {footerLinks.map(item => (
          <a className="link link--bordered" href={item.link} target="_blank" rel="noopener noreferrer">
            {item.label}
          </a>
        ))}
      </div>
      <div>
        <p className="footer__text text--size-sm">
          <span className="copyright">
            © COPYRIGHT {date.getFullYear()} by
            <a
              className="link"
              href="https://developmentaledge.com"
              target="_blank"
              rel="noopener noreferrer">
              &nbsp;The Developmental Edge
            </a>
          </span>
          <span>Designed and built by </span>
          <a
            className="link"
            href="https://speedandfunction.com"
            target="_blank"
            rel="noopener noreferrer">
            Speed &amp; Function
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
