// @flow
import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import { notification } from 'antd';
import {clearMessages} from '../../actions/messages';

function Messages() {
  const dispatch = useDispatch();
  const messages = useSelector(({messages}) => messages);
  const _clearMessages = () => dispatch(clearMessages())

  function onClose() {
    _clearMessages();
  }

  useEffect(() => {
    const {message, messageType} = messages;

    if (!message) {
      return;
    }

    const msgTxt =
      messageType === 'error' ? message.message || 'Unknown Error' : message;

    if (messageType === 'error') {
      notification.error({message: msgTxt, onClose})
    } else {
      notification.success({message: msgTxt, onClose})
    }

    return () => onClose();
    // eslint-disable-next-line
  }, [messages])


  return <></>;
}

export default Messages;
