import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import Restricted from '../../Errors/Restricted';

export default function AuthRoute({children, role, ...routeProps}) {
  const {isAuthenticated, role: userRole, profile} = useIsAuthenticated();

  function renderChildren() {
    return role === userRole ? children : <Restricted/>
  }

  function render({location}) {
    if (isAuthenticated) {
      return profile.agreementSigned
        ? renderChildren()
        : <Redirect to={{pathname: '/auth/agreement', state: {from: location}}}/>
    }

    window.location.href = process.env.REACT_APP_AUTH_URL;

    return null;
  }

  return (
    <Route
      {...routeProps}
      render={render}
    />
  );
}
