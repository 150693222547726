import React, {lazy, Suspense} from 'react';
import {Provider} from 'react-redux';
import {Route, Switch, Redirect} from 'react-router-dom';
import {store, history, persistor} from './store';
import {ConnectedRouter} from 'connected-react-router';
import {PersistGate} from 'redux-persist/integration/react';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFlask, faBars, faCircle} from '@fortawesome/free-solid-svg-icons';
import {AppContextProvider} from './contexts/providers';
import Messages from './components/Messages';
import Spinner from './components/Spinner';
import AuthRoute from './components/Auth/AuthRoute';
import NotFound from './components/Errors/NotFound'
import ErrorBoundary from './components/ErrorBoundary'

const AdminApp = lazy(() => import('./containers/AdminContainer'));
const AuthApp = lazy(() => import('./containers/AuthContainer'));
const UserSprint = lazy(() => import('./containers/UserSprintContainer'));
const UserWorkout = lazy(() => import('./containers/UserWorkoutContainer'));
const UserWorkoutRe = lazy(() => import('./containers/UserWorkoutsReContainer'));
const UserPod = lazy(() => import('./containers/UserPodContainer'));
const UserDashboard = lazy(() => import('./containers/DashboardContainer'));
const Analytics = lazy(() => import('./components/Admin/SprintAnalytics'));

library.add(faFlask, faBars, faCircle);

const isNovartis = Boolean(process.env.REACT_APP_NOVARTIS);

const Workout = isNovartis ? UserWorkoutRe : UserWorkout;

const PageLoader = () => {
  return (
    <div className="page-loader">
      <Spinner fontSize={96} />
    </div>
  );
};

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <AppContextProvider>
          <Messages />
          <Suspense fallback={<PageLoader/>}>
            <ErrorBoundary context={{severity: 'critical', component: 'APP RENDER'}} fallback>
              <Switch>
                <Route path="/auth" component={AuthApp} />
                <AuthRoute path="/admin" role="coach"><AdminApp/></AuthRoute>
                <AuthRoute path="/dashboard" role="participant"><UserDashboard/></AuthRoute>
                <AuthRoute exact path="/sprint/:id" component={UserSprint} />
                <AuthRoute exact path="/workout/:id" component={Workout} />
                <AuthRoute exact path="/pod/:id" component={UserPod} />
                <AuthRoute path="/analytics/:sprintId" role="coach"><Analytics/></AuthRoute>
                <Route exact path="/404" component={NotFound} />
                <Redirect exact from="/" to="auth" />
                <Redirect exact from="*" to="404" />
              </Switch>
            </ErrorBoundary>
          </Suspense>
        </AppContextProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default App;
