import {put, call, takeLatest} from 'redux-saga/effects';

import {
  SIGN_IN,
  ADMIN_SIGNED_IN,
  CREATE_NEW_SPRINT,
  GET_SPRINTS,
  SET_SPRINTS,
  GET_WORKOUTS,
  SET_WORKOUTS,
  ON_MESSAGE,
  SET_COACH_SPRINT,
  SET_COACH_SPRINT_ANALYTICS,
  GET_COACH_SPRINT,
  UPDATE_COACH_SPRINT,
  DELETE_COACH_SPRINT,
  GET_FEEDBACK,
  SET_FEEDBACK,
  UPDATE_FEEDBACK,
  REVIEW_FEEDBACK,
  SET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE,
  SAVE_EMAIL_TEMPLATE,
  LOADING_SPRINTS,
  NEW_MAPS_REQUEST,
  MAP_UPDATE_REQUEST_REQUEST,
  GET_COACH_DATA,
  GET_ADMINS,
  SET_ADMINS,
  DELETE_ADMIN,
  REMOVE_ADMIN,
  ADD_ADMIN,
  CREATE_ADMIN
} from '../../actions/types';

import {onMapRequestSuccess, onUpdateRequestSuccess} from '../../actions/admin';

import Api from '../../utils/api';
import {forwardTo} from '../../utils';
import {notify} from '../../utils/airbrake';

function loginAdminApi(params) {
  return Api.post('/login', params);
}

function getSprintsApi(params) {
  return Api.get('/sprints', params);
}

function getWorkoutsApi(params) {
  return Api.get('/workout/all', params);
}

function getCoachSprintApi(id) {
  return Api.get(`/sprints/${id}`);
}
function getCoachDataApi(id) {
  return Api.get(`/analytics/data/${id}`);
}

function getAdminsApi() {
  return Api.get(`/users/admins`);
}

function createAdminApi(data) {
  return  Api.post('/users/admin', data)
}

function deleteAdminApi(id) {
  return Api.delete(`/users/admin/${id}`);
}

function deleteCoachSprintApi(id) {
  return Api.delete(`/sprints/${id}`);
}

function createNewSprintApi(params) {
  return Api.post('/sprints', params);
}

function updateCoachSprintApi(params) {
  const {id} = params;
  return Api.put(`/sprints/${id}`, params);
}

function reviewFeedbackApi({feedbackId}) {
  return Api.put(`/feedback/${feedbackId}`);
}

function getRequestsApi() {
  return Api.get(`/request`);
}

function approveRequestApi({sprintId, userId}) {
  return Api.post(`/request/approve`, {sprintId, userId});
}

function denyRequestApi({sprintId, userId}) {
  return Api.post(`/request/deny`, {sprintId, userId});
}

function* onError(error) {
  notify({
    error,
    context: { component: 'Admin Sagas', severity: 'error' },
  });

  yield put({
    type: ON_MESSAGE,
    message: error,
    messageType: 'error',
  });
}

function* onSuccess(message) {
  yield put({
    type: ON_MESSAGE,
    message: message,
    messageType: 'success',
  });
}

function* signInAdmin(action) {
  try {
    const {params} = action;
    const loggedIn = yield call(loginAdminApi, params);

    if (loggedIn) {
      yield put({type: ADMIN_SIGNED_IN});
      yield onSuccess('Logged In!');
      forwardTo({location: '/admin/sprints'});
    }
  } catch (error) {
    yield onError(error);
  }
}

function* createNewSprint(action) {
  try {
    const {params} = action;

    yield call(createNewSprintApi, params);
    yield onSuccess('Sprint Created!');
    forwardTo({location: '/admin/sprints'});
  } catch (error) {
    yield onError(error);
  }
}

function* updateCoachSprint(action) {
  try {
    const {params} = action;

    yield call(updateCoachSprintApi, params);
    yield put({type: SET_COACH_SPRINT, sprint: null});
    yield onSuccess('Sprint Updated!');
    forwardTo({location: '/admin/sprints'});
  } catch (error) {
    yield onError(error);
  }
}

function* getSprints() {
  try {
    yield put({type: LOADING_SPRINTS, loading: true});
    const sprints = yield call(getSprintsApi);

    yield put({type: SET_SPRINTS, sprints});
    yield put({type: SET_COACH_SPRINT, sprint: null});
    yield put({type: LOADING_SPRINTS, loading: false});
  } catch (error) {
    yield put({type: LOADING_SPRINTS, loading: false});
    yield onError(error);
  }
}

function* getWorkouts() {
  try {
    yield put({type: LOADING_SPRINTS, loading: true});

    const workouts = yield call(getWorkoutsApi);

    yield put({type: SET_WORKOUTS, workouts});
    yield put({type: LOADING_SPRINTS, loading: false});
  } catch (error) {
    yield put({type: LOADING_SPRINTS, loading: false});
    yield onError(error);
  }
}

function* getCoachSprint(action) {
  try {
    const {id} = action;
    const sprint = yield call(getCoachSprintApi, id);

    yield put({type: SET_COACH_SPRINT, sprint});
  } catch (error) {
    yield put({type: SET_COACH_SPRINT, sprint: null});
    // yield onError(error);
  }
}

function* getCoachSprintData(action) {
  try {
    const {id} = action;
    yield put({type: LOADING_SPRINTS, loading: true});
    const data = yield call(getCoachDataApi, id);

    yield put({type: SET_COACH_SPRINT_ANALYTICS, data});
    yield put({type: LOADING_SPRINTS, loading: false});
  } catch (error) {
    yield put({type: SET_COACH_SPRINT_ANALYTICS, data: null});
    yield put({type: LOADING_SPRINTS, loading: false});
    // yield onError(error);
  }
}

function* getAdmins() {
  try {

    yield put({type: LOADING_SPRINTS, loading: true});
    const admins = yield call(getAdminsApi);

    yield put({type: SET_ADMINS, admins});
    yield put({type: LOADING_SPRINTS, loading: false});
  } catch (error) {
    yield put({type: SET_ADMINS, admins: []});
    yield put({type: LOADING_SPRINTS, loading: false});
    // yield onError(error);
  }
}

function* deleteAdmin(action) {
  try {
    const {id} = action;

    yield put({type: LOADING_SPRINTS, loading: true});
    const data = yield call(deleteAdminApi, id);
    console.log(data);

    yield put({type: REMOVE_ADMIN, id});
    yield put({type: LOADING_SPRINTS, loading: false});
  } catch (error) {
    yield put({type: SET_ADMINS, admins: []});
    yield put({type: LOADING_SPRINTS, loading: false});
    // yield onError(error);
  }
}

function* createAdmin(action) {
  try {
    const {data} = action;

    yield put({type: LOADING_SPRINTS, loading: true});

    const {admin, message} = yield call(createAdminApi, data);

    if (admin) {
      yield onSuccess(message);
      yield put({type: ADD_ADMIN, admin});
    } else {
      yield put({
        type: ON_MESSAGE,
        message: {message},
        messageType: 'error',
      });
    }

    yield put({type: LOADING_SPRINTS, loading: false});
  } catch (error) {
    yield put({type: SET_ADMINS, admins: []});
    yield put({type: LOADING_SPRINTS, loading: false});
    // yield onError(error);
  }
}

function* deleteCoachSprint(action) {
  try {
    const {id} = action;

    yield call(deleteCoachSprintApi, id);

    const sprints = yield call(getSprintsApi);

    yield put({type: SET_SPRINTS, sprints});
    yield onSuccess('Sprint Deleted!');
  } catch (error) {
    yield onError(error);
  }
}

function* getFeedback() {
  try {
    const feedback = yield Api.get('/feedback');

    yield put({type: SET_FEEDBACK, feedback});
  } catch (error) {
    yield onError(error);
  }
}

function* getEmailTemplate() {
  try {
    const emailTemplate = yield Api.get('/email-template');

    yield put({type: SET_EMAIL_TEMPLATE, emailTemplate});
  } catch (error) {
    yield onError(error);
  }
}

function* saveEmailTemplate({template}) {
  try {
    const emailTemplate = yield Api.put('/email-template', {text: template});

    yield put({type: SET_EMAIL_TEMPLATE, emailTemplate});
    yield onSuccess('Email Saved!');
  } catch (error) {
    yield onError(error);
  }
}

function* reviewFeedback(action) {
  try {
    const {params} = action;
    const feedback = yield call(reviewFeedbackApi, params);

    yield put({
      type: UPDATE_FEEDBACK,
      feedbackId: feedback._id,
      fieldToUpdate: 'reviewed',
      value: feedback.reviewed,
    });
  } catch (error) {
    yield onError(error);
  }
}

function* getMapsRequests() {
  try {
    const requests = yield call(getRequestsApi);
    yield put(onMapRequestSuccess(requests));
  } catch (error) {
    yield onError(error);
  }
}

function* requestUpdate({userId, sprintId, actionName}) {
  try {
    const fnApi = actionName === 'approve'
      ? approveRequestApi
      : denyRequestApi

    yield call(fnApi, {sprintId, userId});

    const message = actionName === 'approve' ?
      'New map was approved.':
      'Map was denied.';

    yield onSuccess(message);
    yield put(onUpdateRequestSuccess(userId, sprintId));
  } catch (error) {
    yield onError(error);
  }
}

export function* getAdminSignInWatcher() {
  yield takeLatest(SIGN_IN, signInAdmin);
}

export function* createNewSprintWatcher() {
  yield takeLatest(CREATE_NEW_SPRINT, createNewSprint);
}

export function* getSprintsWatcher() {
  yield takeLatest(GET_SPRINTS, getSprints);
}

export function* getWorkoutsWatcher() {
  yield takeLatest(GET_WORKOUTS, getWorkouts);
}

export function* getCoachSprintWatcher() {
  yield takeLatest(GET_COACH_SPRINT, getCoachSprint);
}

export function* updateCoachSprintWatcher() {
  yield takeLatest(UPDATE_COACH_SPRINT, updateCoachSprint);
}

export function* deleteCoachSprintWatcher() {
  yield takeLatest(DELETE_COACH_SPRINT, deleteCoachSprint);
}

export function* getFeedbackWatcher() {
  yield takeLatest(GET_FEEDBACK, getFeedback);
}

export function* getEmailTemplateWatcher() {
  yield takeLatest(GET_EMAIL_TEMPLATE, getEmailTemplate);
}

export function* saveEmailTemplateWatcher() {
  yield takeLatest(SAVE_EMAIL_TEMPLATE, saveEmailTemplate);
}

export function* reviewFeedbackWatcher() {
  yield takeLatest(REVIEW_FEEDBACK, reviewFeedback);
}

export function* getRequestsWatcher() {
  yield takeLatest(NEW_MAPS_REQUEST, getMapsRequests);
}

export function* requestUpdateWatcher() {
  yield takeLatest(MAP_UPDATE_REQUEST_REQUEST, requestUpdate);
}

export function* getSprintDataWatcher() {
  yield takeLatest(GET_COACH_DATA, getCoachSprintData);
}

export function* getAdminsWatcher() {
  yield takeLatest(GET_ADMINS, getAdmins);
}

export function* removeAdminWatcher() {
  yield takeLatest(DELETE_ADMIN, deleteAdmin);
}

export function* createAdminWatcher() {
  yield takeLatest(CREATE_ADMIN, createAdmin);
}
