// @flow
import React from 'react';
import {Layout, Result} from 'antd';
import {useTranslation} from 'react-i18next'
import Header from '../../Header';
import Footer from '../../Footer';
const {Content} = Layout;

const Restricted = () => {
  const {t} = useTranslation();

  return (
    <Layout>
      <Header unreadFeedbackCount={0} />
      <Content className="main-content main-content--auth">
        <div className="app-panel app-panel--pads">
          <Result
            status="warning"
            title={t('You are not authorized to access this page')}
          />
        </div>
      </Content>
      <Footer />
    </Layout>
  );
};

export default Restricted;
