// @flow
import React from 'react';
import {Select} from 'antd';
import { useTranslation } from 'react-i18next';


const {Option} = Select;

const LanguageSelect = () => {
  const {i18n, t} = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  const currentLang = ['cn', 'en', 'fr'].includes(i18n.language) ? i18n.language : 'en';

  return (
    <Select size="small" className="header__language-selector" defaultValue={currentLang} style={{ width: 100, marginLeft: 12, height: 32 }} onChange={changeLanguage}>
      <Option value="en">{t('English')}</Option>
      <Option value="cn">{t('Chinese')}</Option>
      <Option value="fr">{t('French')}</Option>
    </Select>
  );
};

export default LanguageSelect;
