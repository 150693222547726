import {createReducer} from '../utils';
import {
  SET_USER_PODS,
  SET_USER_POD,
  GET_USER_PODS,
  ON_API_ERROR,
  SET_POD_START_NOTES,
  REMOVE_POD_NOTE,
  SET_POD_SESSION,
} from '../actions/types';

const initialState = {
  pods: []
};

const podReducer = createReducer(initialState, {
  [GET_USER_PODS]: (state) => ({...state, loading: false}),
  [SET_POD_START_NOTES]: (state, {notes, field}) => {
    return {
      ...state,
      currentPod: {
        ...state.currentPod,
        start: {
          ...state.currentPod.start,
          [field]: notes
        },
      }
    };
  },
  [SET_USER_PODS]: (state, {pods}) => {
    return {
      ...state,
      pods,
    };
  },
  [SET_USER_POD]: (state, {pod}) => {
    return {
      ...state,
      currentPod: {
        ...pod,
        currentSession: 0,
      },
    };
  },
  [SET_POD_SESSION]: (state, {params: {currentSession}}) => {
    return {
      ...state,
      currentPod: {
        ...state.currentPod,
        currentSession,
      },
    };
  },
  [REMOVE_POD_NOTE]: (state, {entryId, field}) => {
    const updatedField = state.currentPod.start[field].filter(({_id}) => _id !== entryId);
    console.log(REMOVE_POD_NOTE, updatedField);
    return {
      ...state,
      currentPod: {
        ...state.currentPod,
        start: {
          ...state.currentPod.start,
          [field]: [...updatedField]
        }
      },
    };
  },
  [ON_API_ERROR]: () => initialState
});

export default podReducer;
