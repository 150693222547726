import {createReducer} from '../utils';
import {
  ADMIN_SIGNED_IN,
  LOGGED_IN,
  GET_INVITATION_DATA_REQUEST,
  GET_INVITATION_DATA_SUCCESS,
  ON_CODE_ERROR,
  SIGNUP_REQUEST,
  LOGIN_REQUEST,
  AUTH_SUCCESS,
  ON_API_ERROR,
  ON_SIGN_OUT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  CLEAR_AUTH_STATUSES,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_CODE_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_REQUEST,
  UPDATE_TOKEN,
  SIGN_UP_SUCCESS
} from '../actions/types';

const initialState = {
  isAuthenticated: false,
  token: null,
  codeError: false,
  isFetchingCode: false,
  inProgress: false,
  profile: undefined,
  verification: false,
};

const authReducer = createReducer(initialState, {
  [GET_INVITATION_DATA_REQUEST]: (state) => ({
    ...state,
    isFetchingCode: true,
  }),
  [UPDATE_TOKEN]: (state, {token}) => ({
    ...state,
    token,
  }),
  [GET_INVITATION_DATA_SUCCESS]: (state, {user}) => ({
    ...state,
    isFetchingCode: false,
    codeError: false,
    profile: user,
  }),
  [ON_CODE_ERROR]: (state) => ({
    ...state,
    codeError: true,
    isFetchingCode: false,
    inProgress: false,
  }),
  [SIGNUP_REQUEST]: (state) => ({...state, inProgress: true}),
  [LOGIN_REQUEST]: (state) => ({...state, inProgress: true}),
  [FORGOT_PASSWORD_REQUEST]: (state) => ({...state, inProgress: true}),
  [RESET_PASSWORD_REQUEST]: (state) => ({...state, inProgress: true}),
  [FORGOT_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    inProgress: false,
    forgotSuccess: true
  }),
  [RESET_PASSWORD_CODE_SUCCESS]: (state, {user}) => ({...state, profile: user}),
  [AUTH_SUCCESS]: (state, {token, user}) => ({
    ...state,
    inProgress: false,
    isAuthenticated: true,
    token,
    profile: user,
    role: user.role,
  }),
  [SIGN_UP_SUCCESS]: (state) => ({
    ...state,
    verification: true,
  }),
  [ADMIN_SIGNED_IN]: (state) => ({
    ...state,
    isAuthenticated: true,
  }),
  [LOGGED_IN]: (state, {authData}) => ({
    ...state,
    ...authData,
    isAuthenticated: true,
  }),
  [CLEAR_AUTH_STATUSES]: () => ({
    ...initialState,
    inProgress: false,
    isFetchingCode: false,
    forgotSuccess: false,
    codeError: false,
    forgotError: false
  }),
  [UPDATE_PROFILE_REQUEST]: (state) => ({
    ...state,
    inProgress: true
  }),
  [UPDATE_PROFILE_SUCCESS]: (state, {profile}) => ({
    ...state,
    inProgress: false,
    profile: {...state.profile, ...profile}
  }),
  [ON_API_ERROR]: (state) => ({
    ...state,
    isFetchingCode: false,
    inProgress: false,
    forgotSuccess: false
  }),
  [ON_SIGN_OUT]: () => initialState,
});

export default authReducer;
